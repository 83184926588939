<div class="container-fluid py-5">
    <div>
        <h1 class="my-5 px-5 text-center">Suivi Parrainage</h1>

        <p class="my-5 text-center">
            Retrouver sur cette page l’historique de vos parrains inscrits, et parrains actifs. Vous pouvez les consulter
            soit pour vous-même conseiller clientèle soit pour l’ensemble des conseillers de votre agence.<br><br>
            @if (!hasRole('GIFT_DIRECTOR')) {
                <a [routerLink]="'/consumers/search'" class="ms-2 d-inline-block">
                    <span>Rechercher un parrain spécifique&nbsp;&nbsp;&nbsp;&nbsp;></span>
                </a>
            }
        </p>

        @if (trackingForm) {
            <form [formGroup]="trackingForm">
                <div class="row justify-content-center gap-2">
                    @if (giftUser?.branchList.length > 1) {
                        <mat-form-field class="col-md-6 col-lg-3">
                            <mat-label>Code agence ou point de vente (en chiffres) :</mat-label>
                            <mat-select formControlName="inputBranchCode">
                                <mat-option value=""> Tout</mat-option>
                                @for (branch of branchList; track branch) {
                                    <mat-option [value]="branch">
                                        {{giftService.convertBranchCodeToString(branch)}}
                                    </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    }
                    <mat-form-field appearance="fill" class="col-md-6 col-lg-3">
                        <mat-label>Période prédéfinie</mat-label>
                        <mat-select formControlName="preDefinedRange">
                            <mat-option value="all"> Tout</mat-option>
                            <mat-option value="7">7 derniers jours</mat-option>
                            <mat-option value="30">30 derniers jours</mat-option>
                            <mat-option value="year">Année en cours</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="col-md-6 col-lg-3">
                        <mat-label>Date début</mat-label>
                        <input matInput [matDatepicker]="start" formControlName="start" id="start">
                        <mat-datepicker-toggle matIconSuffix [for]="start" />
                        <mat-datepicker #start />
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="col-md-6 col-lg-3">
                        <mat-label>Date de fin</mat-label>
                        <input matInput [matDatepicker]="end" formControlName="end" id="end">
                        <mat-datepicker-toggle matIconSuffix [for]="end" />
                        <mat-datepicker #end />
                    </mat-form-field>
                </div>

                <div class="row gap-3 justify-content-center">
                    @if (!hasRole('GIFT_DIRECTOR')) {
                        <mat-checkbox formControlName="sponsorsOnly" class="col-md-auto">
                            Afficher uniquement mes parrains
                        </mat-checkbox>
                    }

                    <div class="text-center">
                        <button (click)="getStats()" class="btn btn-validate"> Recherche</button>
                    </div>
                </div>
            </form>
        }

        <div class="row">
            @if (!loading && dataSource?.data.length > 0) {
                <div class="text-center mt-3">
                    <button (click)="download()" class="btn btn-validate">
                        <i aria-hidden="true" class="fas fa-file-excel mx-2"></i> Export Excel
                    </button>
                </div>
            } @else if (loading) {
                <div class="col mx-auto text-center">
                    <button class="btn btn-validate my-2 px-4 py-2" disabled>
                        <mat-spinner color="primary" diameter="30" />
                    </button>
                </div>
            }
        </div>

        <mat-tab-group [hidden]="!dataSource || dataSource.data.length === 0" class="my-4">
            <mat-tab label="Liste des parrainages">
                <div class="responsive-table">
                    <div class="responsive-table-body">
                        <table #sponsorSort="matSort" [dataSource]="dataSource" [hidden]="!dataSource" aria-describedby="Liste des parrainages"
                               class="mat-elevation-z8 w-100"
                               mat-table matSort>
                            @for (column of displayedColumns[0]; track column; let i = $index; let last = $last) {

                                <ng-container [matColumnDef]="column" [stickyEnd]="last">
                                    <th *matHeaderCellDef [mat-sort-header]="column" mat-header-cell scope="col">
                                        {{colNames[0][i]}}
                                    </th>
                                    <td *matCellDef="let element" mat-cell>
                                        @switch (column) {
                                            @case ('isPro') {{{element[column] | titlecase}}}
                                            @case ('sponsorCodeSentAt') {{{element[column] | date: 'yyyy-MM-dd'}}}
                                            @case ('link') {
                                                <a class="clickable text-purple text-nowrap" routerLink="/consumers/{{element.id}}">
                                                    Fiche Client&nbsp;>
                                                </a>
                                            }
                                            @default {
                                                {{element[column]}}
                                            }
                                        }
                                    </td>
                                </ng-container>
                            }

                            <tr *matHeaderRowDef="displayedColumns[0]" mat-header-row></tr>
                            <tr *matRowDef="let row; columns: displayedColumns[0];" mat-row></tr>
                        </table>
                    </div>
                    <mat-paginator #sponsorPaginator
                                   [pageSizeOptions]="[10, 20, 50, 100]"
                                   showFirstLastButtons />
                </div>
            </mat-tab>
            @if (displayedColumns) {
                <mat-tab label="Liste agrégée">
                    <div class="responsive-table">
                        <div class="responsive-table-body">
                            <table #aggregatedGiftUserStatsTableSort="matSort" [dataSource]="dataSource2" aria-describedby="Liste agrégée"
                                   class="mat-elevation-z8 w-100"
                                   mat-table matSort>
                                @for (column of displayedColumns[1]; track column; let i = $index) {
                                    <ng-container [matColumnDef]="column">
                                        <th *matHeaderCellDef [mat-sort-header]="column" mat-header-cell scope="col">
                                            {{colNames[1][i]}}
                                        </th>
                                        <td *matCellDef="let element" mat-cell> {{element[column]}} </td>
                                    </ng-container>
                                }

                                <tr *matHeaderRowDef="displayedColumns[1]" mat-header-row></tr>
                                <tr *matRowDef="let row; columns: displayedColumns[1];" mat-row></tr>
                            </table>
                        </div>
                        <mat-paginator #aggregatedGiftUserStatsTablePaginator [pageSizeOptions]="[10, 20, 50, 100]"
                                       showFirstLastButtons />
                    </div>
                </mat-tab>
            }
        </mat-tab-group>

        @if (dataSource && dataSource.data.length === 0) {
            <div class="text-center my-5">
                Pas de parrain trouvé
            </div>
        }

    </div>
</div>

