import {Component} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {CookiesService} from '@isifid/core';
import {UIService} from '../../../shared/services/ui.service';
import {SponsorshipService} from '../../../shared/services/sponsorship.service';

@Component({
    selector: 'app-sponsorship-dashboard',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent {
    action: string;
    env = environment;
    type: string;

    constructor(
        public readonly uiService: UIService,
        public readonly sponsorshipService: SponsorshipService,
        private readonly cookiesService: CookiesService,
        private route: ActivatedRoute
    ) {
        this.route.paramMap.subscribe((params) => {
            this.action = params.get('action');
            if (this.action === 'check' && this.cookiesService.getCookie('dna_open_account')) this.action = 'validate';
            this.type = params.get('type');
        });
    }
}
