<div class="container-fluid p-md-5 text-center">
    <div class="row mb-5">
        <div class="col">
            <h1>Accueillir un filleul </h1>
        </div>
    </div>

    <div class="row justify-content-center text-white g-0">
        <div [ngClass]="action === 'check' ? 'selected-step' : 'step'"
             class="col-12 col-lg-3 pt-4">
            <h2> 1<sup>ère </sup> étape</h2>
            @if (!this.giftService.getConfigurationValue('ca29Sponsorship')) {
                <p>Entrée en relation</p>
            } @else {
                <p>Prise de Rendez-vous</p>
            }
        </div>
        <div [ngClass]="action === 'validate' ? 'selected-step' : 'step'"
             class="col-12 col-lg-3 pt-4 my-2 my-lg-0 mx-3">
            <h2> 2<sup>ème </sup> étape</h2>
            <p>Validation </p>
        </div>
        <div [ngClass]="action === 'register' ? 'selected-step' : 'step'"
             class="col-12 col-lg-3 pt-4">
            <h2> 3<sup>ème </sup>étape </h2>
            <p>Enregistrement filleul</p>
        </div>
    </div>

    @if (action === 'check') {<app-check (actionEvent)="changeAction($event)" />}
    @else if (action === 'validate') {<app-validate (validateEvent)="validateEvent($event)" />}
    @else if (action === 'register') {
        <app-register [consumerSponsor]="consumerSponsor"
                      [offersSponsor]="offersSponsor"
                      (actionEvent)="changeAction($event)" />
    }
</div>
