import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {TrackingComponent} from './tracking/tracking.component';
import {authGuardChildFn, trackGuardChildFn} from '@isifid/core';
import {QrcodeComponent} from './qrcode/qrcode.component';
import {DiscoverComponent} from './discover/discover.component';

const routes: Routes = [
    {
        path: 'sponsorship',
        canActivateChild: [authGuardChildFn, trackGuardChildFn],
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent
            },
            // Type : sponsor or sponsored
            {
                path: ':type/:action',
                component: DashboardComponent
            },
            {
                path: 'discover',
                component: DiscoverComponent
            },
            {
                path: 'qr-code',
                component: QrcodeComponent
            },
            {
                path: 'tracking',
                component: TrackingComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SponsorshipRoutingModule {
}
