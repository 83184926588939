import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SponsorshipService} from '../../../../../shared/services/sponsorship.service';
import {
    CapitalizePipe,
    ConfigurationService,
    Consumer, CookiesService,
    DateService,
    FormValidators, GiftUser,
    MobileService,
    MsConsumersService,
    Offer,
    UserService
} from '@isifid/core';
import {GiftService} from '../../../../../shared/services/gift.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConsumerSmall} from '../../../../consumers/models/consumerSmall.model';
import {GiftUserService} from '../../../../../shared/services/gift-user.service';
import {DialogUpdateBranchCodeComponent} from '../../../../account/dialog-update-branch-code/dialog-update-branch-code.component';
import {MatDialog} from '@angular/material/dialog';
import {filter} from 'rxjs/operators';
import {MatSelectChange} from '@angular/material/select';
import {CustomFieldService} from '../../../../../shared/services/custom-field.service';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html'
})
export class FormComponent implements OnInit {
    branchList = [];
    consumer: Consumer;
    rewardProperties = [];
    form: FormGroup;
    loading = true;
    maxDate: Date;
    minDate: Date;
    // Add fields for sponsor : externalId and check sponsor email/mobile
    @Input() sponsor: ConsumerSmall;
    @Output() actionEvent = new EventEmitter<string>();
    @Output() rewardSponsorshipEvent = new EventEmitter<{
        sponsor: ConsumerSmall,
        sponsored: Consumer,
        rewardProperties: any[],
        mortgageLoan: boolean
    }>();
    customRewardFields = [];
    mortgageLoanConfig: boolean = false;
    private giftUser: GiftUser;
    private offerSponsored: Offer;

    constructor(
        readonly giftService: GiftService,
        readonly sponsorshipService: SponsorshipService,
        readonly userService: UserService,
        readonly configurationService: ConfigurationService,
        private readonly dialog: MatDialog,
        private readonly dateService: DateService,
        private readonly cookiesService: CookiesService,
        private readonly capitalizePipe: CapitalizePipe,
        private readonly formValidators: FormValidators,
        private readonly giftUserService: GiftUserService,
        private readonly mobileService: MobileService,
        private readonly msConsumersService: MsConsumersService,
        private readonly customFieldService: CustomFieldService,
        private readonly _snackBar: MatSnackBar
    ) {
        this.maxDate = new Date();
        this.minDate = new Date();
        // 120 years old max
        this.minDate.setFullYear(new Date().getFullYear() - 120);
    }

    ngOnInit() {
        if (this.sponsorshipService.settings.sponsorAgeMax && this.sponsorshipService.settings.sponsorAgeMin &&
            this.sponsorshipService.settings.sponsorAgeMax >= this.sponsorshipService.settings.sponsorAgeMin) {
            this.minDate.setFullYear(new Date().getFullYear() - this.sponsorshipService.settings.sponsorAgeMax);
            this.maxDate.setFullYear(new Date().getFullYear() - this.sponsorshipService.settings.sponsorAgeMin);
        } else if (this.sponsorshipService.settings.sponsorAgeMax) {
            this.minDate.setFullYear(new Date().getFullYear() - this.sponsorshipService.settings.sponsorAgeMax);
        } else if (this.sponsorshipService.settings.sponsorAgeMin) {
            this.maxDate.setFullYear(new Date().getFullYear() - this.sponsorshipService.settings.sponsorAgeMin);
        }
        this.consumer = new Consumer();
        // Init form
        this.initForm();
        this.giftUserService.getGiftUser().pipe(filter(s => !!s)).subscribe(s => {
            this.giftUser = s;
            this.branchList = s.branchList.filter(branchCode => branchCode);
            this.initBranchList();
        });
        this.loading = false;
    }

    submit() {
        // Check if sponsored email/mobile is not the same as sponsor
        if (this.sponsor.email === this.form.value.email || this.sponsor.mobile === this.mobileService.formatMobile(this.form.value.mobile ?? '')) {
            this._snackBar.open('L\'email et le numéro de mobile du parrain et du filleul doivent être différent', 'X');
            if (this.sponsor.email === this.form.value.email) {
                this.form.get('email').setErrors({'incorrect': true});
            } else {
                this.form.get('mobile').setErrors({'incorrect': true});
            }
            return;
        }
        // Check if externalId is not the same as sponsor
        if (this.giftService.getConfigurationValue('externalIdRequiredForSponsored') &&
            this.giftService.getConfigurationValue('externalIdRequiredForSponsor') &&
            this.sponsor.externalId === this.form.value.externalId && this.checkExternalId(this.sponsor.externalId)
        ) {
            const idName = this.giftService.giftNetworkVariables?.idName || 'ID client';
            this._snackBar.open(`${idName} du parrain et du filleul doivent être différent`, 'X');
            this.form.get('sponsorExternalId').setErrors({'incorrect': true});
            return;
        }

        if (this.form.invalid) return;
        if (
            this.cookiesService.getCookie('last_branchcode_check') === null ||
            this.dateService.getDatesDiff(this.cookiesService.getCookie('last_branchcode_check') as unknown as Date, new Date(), 'days') > 14
        ) {
            const dialogRef = this.dialog.open(
                DialogUpdateBranchCodeComponent,
                {
                    maxWidth: 650,
                    panelClass: 'dialog-panel',
                    data: {
                        branchCodes: this.branchList
                    }
                }
            );
            dialogRef.afterClosed().subscribe(isUpdated => {
                if (this.branchList.length === 1 || !isUpdated) this.createConsumer();
            });
        } else this.createConsumer();
    }

    private createConsumer(): void {
        this.loading = true;
        // Add reward properties
        this.customRewardFields.forEach(field => {
            if (field.saveInDatabase) this.rewardProperties.push(this.form.get(field.name).value);
        });

        const consumerData: Consumer & {sponsorExternalId?: string} = new Consumer();
        Object.assign(consumerData, this.form.getRawValue());

        consumerData.clientId = this.giftService.settings.clientId;
        consumerData.mobile = this.mobileService.formatMobile(consumerData.mobile);
        if (consumerData.birthDate) consumerData.birthDate = this.convertDateTime(consumerData.birthDate);

        if (this.giftUser?.id) consumerData.giftUserId = this.giftUser.id;

        // Sponsor externalId if externalIdRequiredForSponsor = true and sponsorExternalId is not empty
        if (this.giftService.getConfigurationValue('externalIdRequiredForSponsor') && consumerData.sponsorExternalId) {
            this.setSponsorExternalId(consumerData.sponsorExternalId);
        }

        if (this.sponsorshipService.settings.professionalEnabled && this.form.get('type') && this.form.get('type').value === 'pro') {
            consumerData.configuration = this.configurationService.addValue(consumerData.configuration, 'typology', 'pro');
        }

        this.msConsumersService.createConsumer(consumerData, true).subscribe({
            next: (consumer) => {
                // If status = 200 consumer already exists, if status = 201 created
                if (consumer.status === 201 || consumer.status === 200) {
                    this.consumer = consumer.body;
                    // Add properties to the user
                    this.populateConsumer(consumer.body);
                    // Send sponsored
                    this.rewardSponsorshipEvent.emit({
                        sponsor: this.sponsor,
                        sponsored: this.consumer,
                        rewardProperties: this.rewardProperties,
                        mortgageLoan: this.form.controls.mortgageLoan.value
                    });
                }
            }, error: () => console.error('error while creating consumer'),
            complete: () => this.loading = false
        });
    }

    formatPhone() {
        // Format phone : only numbers + ! and remove spaces
        const phone = this.form.get('mobile').value;
        if (phone && (phone.indexOf('.') !== -1 || phone.indexOf(' ') !== -1 || phone.indexOf('-') !== -1)) {
            this.form.get('mobile').setValue(this.mobileService.cleanPhoneNumber(this.form.get('mobile').value));
        }
    }

    private initForm() {
        this.form = new FormGroup({
            email: new FormControl(this.consumer.email, [Validators.required, Validators.email, this.formValidators.isEmail]),
            mobile: new FormControl(this.consumer.mobile, [this.formValidators.isInternationalPhone, Validators.required]),
            birthDate: new FormControl(this.consumer.birthDate, [Validators.required,
                this.formValidators.dateMax(this.dateService.computeDate(this.maxDate, 'yyyy-MM-dd')),
                this.formValidators.dateMin(this.dateService.computeDate(this.minDate, 'yyyy-MM-dd'))]),
            branchCode: new FormControl('', [Validators.required]),
            mortgageLoan: new FormControl(false)
        });

        if (!this.giftService.getConfigurationValue('simpleReward')) {
            this.form.addControl('firstName', new FormControl('', [Validators.required, this.formValidators.isName]));
            this.form.addControl('lastName', new FormControl('', [Validators.required, this.formValidators.isName]));
            this.form.addControl('gender', new FormControl(''));
        }


        // If externalIdRequiredForSponsored is true, set externalId as required (only for sponsorship)
        if (this.giftService.getConfigurationValue('externalIdRequiredForSponsored')) {
            this.form.addControl('externalId', new FormControl(this.consumer.externalId, [Validators.required, Validators.pattern('^[0-9]*$')]));
        }

        // Add a field for sponsor externalId if sponsor externalId is empty and externalIdRequiredForSponsor is true
        if (this.giftService.getConfigurationValue('externalIdRequiredForSponsor') && this.checkExternalId(this.sponsor.externalId)) {
            this.form.addControl('sponsorExternalId', new FormControl('', [
                Validators.pattern('^[0-9]*$'), Validators.required
            ]));
        }

        // Add professionalEnabled if set
        if (this.sponsorshipService.settings.professionalEnabled) this.form.addControl('type', new FormControl('pro'));
        this.initCustomFields();
    }

    initCustomFields(sponsoredIsPro = false) {
        this.customRewardFields = [];
        // Get the sponsored offer
        this.sponsorshipService.getSponsoredOffers(sponsoredIsPro).subscribe((offers: Offer[]) => {
            this.offerSponsored = offers?.[0];
            this.mortgageLoanConfig = offers?.some((s:Offer) => !!this.configurationService.getValueByKey(s.configuration, 'mortgageLoan'));
            if (!this.offerSponsored) return;

            // Filter customFormFields with offer id
            this.customRewardFields = [
                ...this.customFieldService.getCustomFieldsFromConfiguration(this.sponsorshipService.operation.configuration),
                ...this.customFieldService.getCustomFieldsFromConfiguration(this.offerSponsored.configuration)
            ];

            this.customRewardFields.forEach(field => {
                this.form.addControl(field.name, new FormControl(field.defaultValue || '', [field.required ? Validators.required : null]));
            });
        });
    }

    checkExternalId(externalId: string): boolean {
        return !externalId || (externalId.length > 1 && externalId[1] === ':');
    }

    capitalizeInput(event: any) {
        this.form.get(event.target.id).setValue(this.capitalizePipe.transform(event.target.value));
    }

    populateConsumer(consumer): void {
        let branchCode;
        if (this.userService.hasRole('GIFT_MANAGER')) branchCode = this.form.get('branchCode').value;
        else branchCode = this.branchList[0];

        const data = {
            branchCode: this.giftService.convertBranchCodeToString(branchCode),
            giftUserId: this.giftUser.id
        };

        this.msConsumersService.updateConsumer(consumer.id, data).subscribe();
    }

    private initBranchList(): void {
        if (this.branchList?.length) this.form.get('branchCode').setValue(this.giftUser.branchList[0]);
        else if (this.userService.hasRole('GIFT_HQ')) this.form.get('branchCode').setValue('siege');
    }

    private setSponsorExternalId(sponsorExternalId: string) {
        this.msConsumersService.updateConsumer(this.sponsor.id, {externalId: sponsorExternalId}).subscribe({
            error: () => console.error('error while updating sponsor externalId ' + this.sponsor.id)
        });
    }

    getErrorMessage(f): string {
        if (this.form.controls[f].hasError('required')) return 'Champ requis';
        if (this.form.controls[f].hasError('email')) return 'Email non valide';
        if (this.form.controls[f].hasError('minlength')) return 'Minimum ' + this.form.get(f).errors['minlength']['requiredLength'] + ' caractères';
        if (this.form.controls[f].hasError('maxlength')) return 'Maximum ' + this.form.get(f).errors['maxlength']['requiredLength'] + ' caractères';
        if (this.form.controls[f].invalid) return 'Champ invalide';
    }

    updateSponsoredOffer(event: MatSelectChange) {
        this.initCustomFields(event.value === 'pro');
    }

    private convertDateTime(value: string): string {
        const date = new Date(value);
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date as unknown as string;
    }
}
