import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    ConfigurationService,
    Consumer,
    CookiesService,
    DateService,
    FormValidators,
    GiftUser,
    MobileService,
    Offer,
    SponsorshipUser
} from '@isifid/core';
import {ConsumerService} from '../../../shared/services/consumer.service';
import {SponsorshipService} from '../../../shared/services/sponsorship.service';
import {Router} from '@angular/router';
import {GiftService} from '../../../shared/services/gift.service';
import {GiftUserService} from '../../../shared/services/gift-user.service';
import {MatDialog} from '@angular/material/dialog';
import {
    DialogUpdateBranchCodeComponent
} from '../../account/dialog-update-branch-code/dialog-update-branch-code.component';
import {filter} from 'rxjs/operators';
import {Observable, tap} from 'rxjs';
import {CustomFieldService} from '../../../shared/services/custom-field.service';

@Component({
    selector: 'app-sponsor',
    templateUrl: './sponsor.component.html'
})
export class SponsorComponent implements OnInit {
    customRewardFields = [];
    offerSponsor: Offer;
    sponsorForm: FormGroup;
    sponsorCode: string;
    isAlreadyRegisteredSponsor: boolean;
    branchCodes: string[] = [];
    mortgageLoanConfig: boolean;
    askForExpectedSponsoredType: boolean = false;
    private giftUser: GiftUser;
    private consumer: Consumer;
    @Input() action: string;

    constructor(
        public readonly giftService: GiftService,
        public readonly sponsorshipService: SponsorshipService,
        private router: Router,
        private readonly dialog: MatDialog,
        private readonly dateService: DateService,
        private readonly cookiesService: CookiesService,
        private readonly consumerService: ConsumerService,
        private readonly formBuilder: FormBuilder,
        private readonly formValidators: FormValidators,
        private readonly giftUserService: GiftUserService,
        private readonly mobileService: MobileService,
        private readonly customFieldService: CustomFieldService,
        private readonly configurationService: ConfigurationService
    ) {
    }

    ngOnInit() {
        this.getOfferSponsor().subscribe(() => {
            this.sponsorForm = this.formBuilder.group({
                mobile: ['', [Validators.required, this.formValidators.isInternationalPhone]],
                branchCode: '',
                checkAge: [false, [Validators.requiredTrue]],
            });

            this.getCustomFieldsForSponsor();

            if (this.giftService.getConfigurationValue('askForExpectedSponsoredType')) {
                this.askForExpectedSponsoredType = this.giftService.getConfigurationValue('askForExpectedSponsoredType');
                this.sponsorForm.addControl('expectedSponsoredType', this.formBuilder.control('', Validators.required));
            }
            if (this.giftService.getConfigurationValue('externalIdRequiredForSponsor')) {
                this.sponsorForm.addControl('externalId',
                    this.formBuilder.control('', [
                            Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(this.giftService.giftNetworkVariables?.idLength || 30)
                        ]
                    ));
            }
            if (this.sponsorshipService.settings.professionalEnabled) {
                this.sponsorForm.addControl('type', this.formBuilder.control(''));
            }

            this.giftUserService.getGiftUser().pipe(filter(s => !!s)).subscribe(s => {
                this.giftUser = s;
                this.branchCodes = s.branchList?.map(s => this.giftService.convertBranchCodeToString(s)) ?? [];
                this.updateBranchCodeField();
            });
        });

        if (this.router.url.includes('/confirmation')) this.router.navigate(['/sponsorship', 'sponsor', 'invite']).then();
    }

    getOfferSponsor(): Observable<null | Offer[]> {
        return this.sponsorshipService.getSponsorOffers(false)
            .pipe(
                tap(offers => {
                    this.offerSponsor = offers?.[0];
                    this.mortgageLoanConfig = offers?.some((s: Offer) => {
                        return !!this.configurationService.getValueByKey(s.configuration, 'mortgageLoan');
                    })
                    if (!this.offerSponsor) console.error('Missing sponsor offers for sponsorship operation');
                })
            );
    }

    getCustomFieldsForSponsor() {
        this.customRewardFields = [];
        if (this.offerSponsor) {
            // Get custom fields from the operation and offer
            this.customRewardFields = [
                ...this.customFieldService.getCustomFieldsFromConfiguration(this.sponsorshipService.operation.configuration),
                ...this.customFieldService.getCustomFieldsFromConfiguration(this.offerSponsor.configuration)
            ];
        }
        if (this.customRewardFields.length) {
            this.sponsorForm.removeControl('checkAge');
            this.sponsorForm.addControl('customRewardFields', this.formBuilder.control('', Validators.required));
        }
    }

    submit() {
        if (this.sponsorForm.invalid) return;
        if (
            this.cookiesService.getCookie('last_branchcode_check') === null ||
            this.dateService.getDatesDiff(this.cookiesService.getCookie('last_branchcode_check') as unknown as Date, new Date(), 'days') > 14
        ) {
            const dialogRef = this.dialog.open(
                DialogUpdateBranchCodeComponent,
                {
                    maxWidth: 650,
                    panelClass: 'dialog-panel',
                    data: {
                        branchCodes: this.branchCodes
                    }
                }
            );
            dialogRef.afterClosed().subscribe(isUpdated => {
                if (this.branchCodes.length === 1 || !isUpdated) this.handleSponsorshipUser();
            });
        } else this.handleSponsorshipUser();
    }

    private resendAccess(sponsorshipUser: SponsorshipUser) {
        this.sponsorshipService.sendSponsorAccess(sponsorshipUser.consumerId).subscribe({
            // If codeCustomised is set use it else use code
            next: () => this.sponsorCode = sponsorshipUser.codeCustomised || sponsorshipUser.code,
            complete: () => {
                this.router.navigate(['/sponsorship', 'sponsor', 'confirmation']).then();
            }
        });
    }

    inviteNewSponsor() {
        this.sponsorForm.reset();
        this.updateBranchCodeField();
        this.sponsorCode = '';
        this.action = 'invite';
        this.router.navigate(['/sponsorship', 'sponsor', 'invite']).then();
    }

    cleanPhoneNumber() {
        // Only keep numbers and + and !
        this.sponsorForm.get('mobile').setValue(this.mobileService.cleanPhoneNumber(this.sponsorForm.get('mobile').value));
    }

    private updateBranchCodeField(): void {
        if (this.branchCodes.length === 1) {
            this.sponsorForm.controls.branchCode.setValue(this.branchCodes[0]);
            this.sponsorForm.controls.branchCode.clearValidators();
        } else if (this.branchCodes.length > 1) {
            this.sponsorForm.controls.branchCode.setValue('');
            this.sponsorForm.controls.branchCode.setValidators(Validators.required);
        } else {
            this.sponsorForm.controls.branchCode.setValue('');
            this.sponsorForm.controls.branchCode.clearValidators();
        }
    }

    private handleSponsorshipUser(): void {
        let type: string = '';
        this.isAlreadyRegisteredSponsor = false;
        this.consumer = new Consumer();
        this.consumer.clientId = this.sponsorshipService.settings.clientId;
        this.consumer.mobile = this.mobileService.formatMobile(this.sponsorForm.get('mobile').value);

        if (this.askForExpectedSponsoredType)
            this.consumer.configuration = JSON.stringify({expectedSponsoredType: this.sponsorForm.get('expectedSponsoredType')?.value});

        if (this.sponsorForm.controls.branchCode) {
            this.consumer.branchCode = this.giftService.convertBranchCodeToString(this.sponsorForm.controls.branchCode.value);
        }
        if (this.giftUser.id) this.consumer.giftUserId = this.giftUser.id;
        if (this.giftUser.clientId) this.consumer.clientId = Number(this.giftUser.clientId);
        if (this.sponsorshipService.settings.professionalEnabled) type = this.sponsorForm.getRawValue().type;

        if (this.giftService.getConfigurationValue('externalIdRequiredForSponsor')) {
            this.consumer.externalId = this.sponsorForm.getRawValue().externalId;
        }

        // Check if consumer already exist
        this.consumerService.searchConsumer(this.consumer).subscribe({
            next: (consumers) => {
                if (consumers?.length > 0) {
                    // If consumer already exist check if he is already sponsor
                    this.sponsorshipService.getSponsorshipUserByConsumerId(consumers[0].id).subscribe({
                        next: (sponsorshipUser) => {
                            this.isAlreadyRegisteredSponsor = true;
                            // If consumer is already a sponsor resend access else create sponsorship user
                            if (sponsorshipUser?.code) this.resendAccess(sponsorshipUser);
                            else this.sponsorshipService.becomeSponsorshipUser(consumers[0], sponsorshipUser, type).subscribe();
                        },
                        error: () => console.error('Error while getting sponsorship user'),
                        complete: () => {
                            this.router.navigate(['/sponsorship', 'sponsor', 'confirmation']).then();
                        }
                    });
                } else {
                    // If consumer doesn't exist create it and create sponsorshipUser
                    this.consumerService.createConsumer(this.consumer).subscribe({
                        next: (consumer) => this.sponsorshipService.createSponsorshipUser(consumer, type)
                            .subscribe((s) => this.sponsorCode = s.code),
                        error: () => console.error('Error while creating consumer'),
                        complete: () => {
                            this.router.navigate(['/sponsorship', 'sponsor', 'confirmation']).then();
                        }
                    });
                }
            },
            error: () => console.error('Error while searching consumer')
        });
    }
}
