<div class="mt-5">
    <form (ngSubmit)="checkAccountSponsored()" [formGroup]="checkForm">
        
        @if (sponsorshipService.operation?.description) {
            <div class="row">
                <div class="bg-dark-blue text-white p-3 col-12 col-md-10 col-lg-7 col-xl-5 mx-auto mb-5">
                    <div [innerHTML]="sponsorshipService.operation.description"></div>
                </div>
            </div>
        }

        <div class="row">
            <div class="col-12 col-md-10 col-lg-7 col-xl-5 mx-auto d-flex align-items-center">
                <div>
                    <input formControlName="accountOpen" id="accountOpen" name="accountOpen" required type="checkbox">
                </div>

                <h3 class="ms-2 mb-0 d-inline clickable">
                    @if (!this.giftService.getConfigurationValue('ca29Sponsorship')) {
                        <label class="check-box" for="accountOpen">
                            Le filleul a bien engagé une relation éligible avec votre établissement
                        </label>
                    } @else {
                        <label class="check-box" for="accountOpen">
                            Le filleul a bien pris rendez-vous avec le Crédit Agricole du Finistère
                        </label>
                    }
                </h3>
            </div>

        </div>

        <div class="row mb-5 mt-4">
            <div class="col">
                <input formControlName="remember" id="remember" name="remember" type="checkbox">
                <label class="ms-2 clickable check-box" for="remember">Ne plus me le demander pendant 90 jours.</label>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <button [disabled]="!checkForm.get('accountOpen').value" class="btn btn-validate mt-4"
                        type="submit">
                    Valider
                </button>
            </div>
        </div>
    </form>
</div>
