@if (sponsorForm) {
    @if (sponsorshipService.hasSponsorshipOnline) {
        <div class="mt-5 pt-5">
            <h2 class="clickable hover-purple" routerLink="/sponsorship/qr-code">
                <img alt="scan icon" class="pb-4 icon" height="" src="assets/icons/scan.svg" width="">
                Mon client flashe mon QR code
            </h2>
            <p>
                Il accède directement à la plateforme de recommandation.<br>
                Depuis son smartphone, il s'inscrit et peut déjà lancer<br> des recommandations durant l'entretien.
                <br>
                <a routerLink="/sponsorship/qr-code">Afficher mon QR Code</a>
            </p>
        </div>
    }

    <div class="mt-5">
        <h2>
            <img alt="user circle plus icon" class="pb-4 icon"
                 height="" src="assets/icons/user-circle-plus.svg" width="">
            J'inscris moi-même mon client
        </h2>
        <div class="mt-4">
            @if (action === 'invite') {

                <form (ngSubmit)="submit()" [formGroup]="sponsorForm">
                    <div class="row mt-2">
                        <div class="col col-xl-8 pe-5">
                            <mat-form-field>
                                <mat-label>Son mobile</mat-label>
                                <input matInput
                                       (change)="cleanPhoneNumber()"
                                       formControlName="mobile"
                                       id="mobile"
                                       type="tel" />
                            </mat-form-field>
                        </div>
                    </div>

                    @if (giftService.getConfigurationValue('externalIdRequiredForSponsor')) {

                        <div class="row my-2">
                            <div class="col col-xl-8 pe-5">
                                <mat-form-field>
                                    <mat-label>
                                        @if (giftService.giftNetworkVariables?.idName) {
                                            {{giftService.giftNetworkVariables?.idName | titlecase }}
                                        } @else {
                                            ID * à {{giftService.giftNetworkVariables?.idLength || 30}} chiffres)
                                        }
                                    </mat-label>
                                    <input matInput
                                           (change)="cleanPhoneNumber()"
                                           formControlName="externalId"
                                           id="externalId"
                                           type="text"
                                           [maxlength]="giftService.giftNetworkVariables?.idLength || 30"/>
                                </mat-form-field>
                            </div>
                        </div>
                    }

                    @if (customRewardFields.length === 0) {
                        <div class="row my-2">
                            <div class="col pe-5">
                                <input formControlName="checkAge"
                                       id="checkAge"
                                       required="true"
                                       type="checkbox">
                                <label class="ms-2 check-box" for="checkAge">
                                    @if (sponsorshipService.settings.sponsorAgeMax < 30) {
                                        <span>Votre client a entre
                                            {{ sponsorshipService.settings.sponsorAgeMin || 18 }} et
                                            {{ sponsorshipService.settings.sponsorAgeMax || 120 }} ans.
                                        </span>
                                    } @else if (sponsorshipService.settings.sponsorAgeMax > 30) {
                                        <span>Votre client a plus de {{ sponsorshipService.settings.sponsorAgeMin || 18 }} ans.</span>
                                    }
                                </label>
                                @if (sponsorForm.get('checkAge').invalid && sponsorForm.touched) {
                                    <p>
                                        <br>Merci de confirmer l'âge de votre client.
                                    </p>
                                }
                            </div>
                        </div>
                    }

                    @if (sponsorshipService.settings.professionalEnabled) {
                        <div class="row mt-4">
                            <div class="d-flex align-items-center">
                                <mat-form-field appearance="fill" class="w-50">
                                    <mat-label>Civilité</mat-label>
                                    <mat-select formControlName="type"
                                                data-cy="pro-select">
                                        <mat-option value="">Particulier</mat-option>
                                        <mat-option value="pro" data-cy="pro">Professionnel</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    }
                    @if (branchCodes.length > 1) {
                        <div class="row mt-4">
                            <p>
                                Merci de sélectionner votre code agence ou point de vente :
                            </p>
                            <div class="d-flex align-items-center">
                                <select class="form-select form-select-lg w-50"
                                        data-cy="branchCode-select"
                                        formControlName="branchCode">
                                    <option [value]="''" disabled>Code agence</option>
                                    @for (branchCode of branchCodes; track branchCode; let i = $index) {
                                        <option [value]="branchCode">
                                            {{branchCode}}
                                        </option>
                                    }
                                </select>
                            </div>
                        </div>
                    }

                    @if (customRewardFields?.length) {
                        <div>
                            <input formControlName="customRewardFields" id="customRewardFields" required type="checkbox">
                            <label class="m-2 check-box" for="customRewardFields">
                                Votre client respecte bien les critères d'éligibilité à l'offre<sup>*</sup>
                            </label>

                            <ul>
                                @for (custom of customRewardFields; track i; let i = $index) {
                                    @if (custom.type === 'condition') {
                                        <div class="my-1">
                                            <li>{{custom.name}}</li>
                                        </div>
                                    }
                                }
                            </ul>
                        </div>
                    }
                    @if (askForExpectedSponsoredType && mortgageLoanConfig) {
                        <div class="row mt-5">
                            <p class="fs-5 fw-medium">Type de parrainage attendu :</p>
                            <mat-form-field appearance="fill" class="col col-xl-8 pe-5">
                                <mat-label>Type</mat-label>
                                <mat-select formControlName="expectedSponsoredType">
                                    @for (item of sponsorshipService.expectedSponsoredType; track i; let i = $index) {
                                        <mat-option [value]="item">
                                            {{item}}
                                        </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                    }

                    <div class="row my-3">
                        <div class="col">
                            <button class="btn btn-validate" type="submit">Valider</button>
                            @if (action !== 'invite') {
                                <button (click)="inviteNewSponsor()" class="btn btn-validate ms-5" type="button">
                                    Inscrire un autre parrain
                                </button>
                            }
                        </div>
                    </div>
                </form>
            }

            @if (action === 'confirmation') {
                <div class="row my-3">
                    <div class="col">
                        <button (click)="inviteNewSponsor()" class="btn btn-validate ms-5">
                            Inscrire un autre parrain
                        </button>
                    </div>
                </div>
            }

            @if (action === 'invite') {
                <p class="text-grey">
                    Votre client reçoit immédiatement un SMS contenant son code parrain
                    @if (sponsorshipService.hasSponsorshipOnline) {
                        <span>et lui donnant accès à son espace de recommandation</span>
                    }
                    .<br>
                    @if (sponsorshipService.hasSponsorshipOnline) {
                        <span class="text-grey small font-italic">
                            * Votre client complètera lui-même ses coordonnées sur son espace de recommandation, son opt-in et son code.
                        </span>
                    }
                </p>
            }

            @if (action === 'confirmation') {
                @if (isAlreadyRegisteredSponsor) {
                    <p>
                        Votre client est déjà enregistré. Un SMS contenant son code parrain lui a été envoyé.<br>
                        Voici son code : <span class="fw-bold text-dark-blue">{{sponsorCode}}</span>
                    </p>
                } @else {
                    <h3 class="mt-1">Votre client a été inscrit.</h3>
                    @if (sponsorshipService.hasSponsorshipOnline) {
                        <p class="text-grey">
                            Son accès et son code de parrainage lui ont été envoyés par sms.
                            Il pourra compléter ses coordonnées, son opt-in et personnaliser son code.<br>
                            Voici son code : <span class="fw-bold text-dark-blue">{{sponsorCode}}</span>
                        </p>
                    }
                }
            }
        </div>
    </div>
}
