import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CookiesService} from '@isifid/core';
import {GiftService} from '../../../../shared/services/gift.service';
import { SponsorshipService } from 'src/app/shared/services/sponsorship.service';

@Component({
    selector: 'app-check',
    templateUrl: './check.component.html'
})
export class CheckComponent {
    checkForm: FormGroup;
    @Output() actionEvent = new EventEmitter<string>();

    constructor(
        public readonly giftService: GiftService,
        public readonly sponsorshipService: SponsorshipService,
        private readonly cookiesService: CookiesService,
        private readonly formBuilder: FormBuilder
    ) {
        this.checkForm = this.formBuilder.group({
            accountOpen: ['', Validators.required],
            remember: ['']
        });
    }

    checkAccountSponsored() {
        if (this.checkForm.invalid) {
            this.checkForm.get('accountOpen').markAsDirty();
            return;
        }

        if (this.checkForm.get('remember').value) this.cookiesService.setCookie('dna_open_account', true, 90); // 90 days
        this.actionEvent.emit('validate');
    }
}
