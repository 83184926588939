@if (!type || type === 'sponsor') {
    <div class="row g-0 ps-5">
        @if (!action) {
            <div class="col h-100 d-flex flex-column justify-content-center px-2 px-md-3 pe-xl-4 pt-5 my-auto">
                @if (sponsorshipService.hasSponsorshipOnline) {
                    <div>
                        <h2>
                        <img alt="discover icon" class="me-3 icon" height="" src="assets/icons/discover.svg" width="">
                            <a class="text-dark-blue" routerLink="/sponsorship/discover">
                                Découvrir le circuit parrain-filleul
                            </a>
                        </h2>
                    </div>
                }
                <div class="mt-3">
                    <h2>
                        <img alt="user circle icon" class="me-3 icon" height=""
                             src="assets/icons/user-circle-plus.svg" width="">
                        <a [routerLink]="['/sponsorship', 'sponsor', 'invite']"
                           class="text-dark-blue">Inscrire votre client comme parrain</a>
                    </h2>
                </div>
                <div class="mt-3">
                    <h2>
                        <img alt="hands heart icon" class="me-3 icon" height="" src="assets/icons/hands-heart.svg" width="">
                        <a [routerLink]="['/sponsorship', 'sponsored', 'check']"
                           class="text-dark-blue">Accueillir un filleul</a>
                    </h2>
                </div>
                <div class="mt-3">
                    <h2>
                        <img alt="search icon" class="me-3 icon" height="" src="assets/icons/search.svg" width="">
                        <a class="text-dark-blue" routerLink="/consumers/search">
                            Rechercher un client
                        </a>
                    </h2>
                </div>
            </div>
        }
        <!-- Sponsor -->
        @if (action) {
            <div class="col-12 col-md px-2 px-md-3">
                <app-sponsor [action]="action" />
            </div>
        }

        <div class="col-12 col-lg-6 text-center px-5">
            <img [src]="env.cdnUrl + '/gift/img/sponsorship/discover.svg'"
                 alt="Circuit parrain filleul"
                 class="p-5" height="auto" width="100%">
        </div>
    </div>
    <div class="row g-0 mt-1 mb-5">
        <div class="col text-center">
            <button class="btn-validate px-5 py-1" routerLink="/home">Retour</button>
        </div>
    </div>
}

<!-- Sponsored -->
@if (type === 'sponsored') {
    <app-sponsored [action]="action" />
}
