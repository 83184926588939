<div class="px-5">
    <div class="row">
        <div class="col-12 col-xl-6 mt-3">

            <div (click)="step = 0; showMoreRegister = false; showMoreReferral = false"
                 class="clickable w-100 bg-white d-flex py-2 px-3 mt-5">
                <h2 class="my-auto text-purple clickable">Vous</h2>
                @if (step === 0) {
                    <i aria-hidden="true" class="fas fa-2x fa-caret-up ms-auto text-purple"></i>
                } @else {
                    <i aria-hidden="true" class="fas fa-2x fa-caret-down ms-auto text-purple"></i>
                }

            </div>

            <!--   Step 0   -->
            @if (step === 0) {
                <p class="mt-3">
                    Tout part de vous, tout revient à vous!<br>
                    <span class="fw-bold">Au départ</span>,
                    <a (click)="showMoreRegister = true; showMoreReferral = false" class="clickable text-purple">
                        vous inscrivez
                    </a>votre client
                    <a (click)="showMoreReferral = true; showMoreRegister = false" class="clickable text-purple">
                        sur la plateforme de recommandation
                    </a>,
                    même si celui-ci ne sait pas toujours qui il va parrainer. Grâce à cette plateforme, il pourra partager
                    facilement son code parrain avec ses proches.<br>
                    Votre intérêt est d’inscrire de nombreux clients car, en moyenne, un parrain inscrit sur trois vous
                    apporte un filleul.<br>
                    <span class="fw-bold">À l’arrivée</span>, vous accueillez de nouveaux clients (les filleuls) qui vous
                    présentent le code de leur parrain. Il vous suffit de le saisir à la rubrique Accueillir un filleul afin
                    que parrain et filleul soient immédiatement récompensés.
                </p>
            }

            <div (click)="step = 1;showSecondSlideStep2 = false" class="clickable w-100 bg-white d-flex py-2 px-3 my-2">
                <h2 class="my-auto text-purple clickable">Votre client</h2>
                @if (step === 1) {
                    <i aria-hidden="true" class="fas fa-2x fa-caret-up ms-auto text-purple"></i>
                } @else {
                    <i aria-hidden="true" class="fas fa-2x fa-caret-down ms-auto text-purple"></i>
                }
            </div>

            <!--   Step 1   -->
            @if (step === 1) {
                <p class="mt-3">
                    Votre client étant inscrit instantanément durant l’entretien, il est déjà en mesure de vous
                    <a (click)="showSecondSlideStep2 = true" class="clickable text-purple">recommander à ses proches à
                        partir de son smartphone</a>.<br>
                    Il aura bien d’autres occasions de le faire par la suite mais il est préférable qu’il commence avec vous
                    et qu’il constate à quel point c’est simple!<br>
                    Les messages les plus efficaces sont les SMS et les emails; ils nécessitent donc que votre client
                    saisisse les adresses email et les numéros de mobile des proches auxquels il souhaite vous recommander.
                </p>
            }

            <div (click)="step = 2; showSms = false; showSocialNetwork = false"
                 class="clickable w-100 bg-white d-flex py-2 px-3">
                <h2 class="my-auto text-purple clickable">Ses proches</h2>
                @if (step === 2) {
                    <i aria-hidden="true" class="fas fa-2x fa-caret-up ms-auto text-purple"></i>
                } @else {
                    <i aria-hidden="true" class="fas fa-2x fa-caret-down ms-auto text-purple"></i>
                }
            </div>

            <!--   Step 2   -->
            @if (step === 2) {
                <p class="mt-3">
                    Les proches de votre client reçoivent un message de sa part par email,
                    <a (click)="showSms = true" class="clickable text-purple">SMS</a> ou
                    <a (click)="showSocialNetwork = true" class="clickable text-purple">réseaux sociaux</a>.<br>
                    Ce message leur donne accès à un formulaire de prise de RDV. Il leur est demandé de se munir de leur code
                    Parrain lors du RDV d’ouverture de compte.
                </p>
            }

            <div class="ps-5">
                <button class="btn btn-validate my-5 ms-5" routerLink="/sponsorship/dashboard">Retour</button>
            </div>
        </div>

        <div class="col-12 col-lg-6 px-5">
            <!--   Step 0   -->
            @if (step === 0 && !showMoreRegister && !showMoreReferral) {
                <div>
                    <img [src]="env.cdnUrl + '/gift/img/sponsorship/discover.svg'"
                         alt="Circuit parrain filleul"
                         class="p-5" height="auto" width="100%">
                </div>
            }
            @if (step === 0 && showMoreRegister) {
                <div class="mt-5">
                    <h1>2 solutions</h1>

                    <h2 class="mt-4 ps-4 text-dark-blue">
                        <button class="fw-light bg-dark-blue text-white cursor-default" mat-fab>1</button>
                        <span class="ps-3">Votre client s’inscrit devant vous</span>
                    </h2>
                    <p class="mt-2 text-start ps-5 ms-5">Il flashe votre QR code avec son smartphone. Il accède
                        instantanément à la plateforme de recommandation et s’inscrit. Il peut saisir tout de suite des
                        filleuls.<br>
                        <span class="fw-bold">Les +</span>
                        Gain de temps / Le client utilise la plateforme de recommandation devant vous<br>
                        <span class="fw-bold">Les -</span>
                        Attention, votre client doit s’inscrire devant vous. Après, ce sera trop tard.
                    </p>

                    <h2 class="mt-4 ps-4 text-dark-blue">
                        <button class="fw-light bg-dark-blue text-white cursor-default" mat-fab>2</button>
                        <span class="ps-3">Vous inscrivez votre client</span>
                    </h2>
                    <p class="mt-2 text-start ps-5 ms-5">Votre client ne veut ou ne peut flasher votre QR code. Vous
                        l’inscrivez vous-même sur la présente application. Il reçoit instantanément un email et un SMS et
                        peut lui aussi saisir des filleuls tout de suite mais c’est moins naturel.<br>
                        <span class="fw-bold">Les +</span> Vous contrôlez l’inscription de votre client<br>
                        <span class="fw-bold">Les -</span>
                        Le client n’utilise pas forcément la plateforme de recommandation devant vous.
                    </p>
                </div>
            }
            @if (step === 0 && showMoreReferral) {
                <div class="mt-5 pt-5">
                    <p>La plateforme de recommandation est l’espace personnel de votre client, espace sur lequel il dispose
                        de tous les outils pour vous recommander facilement à ses proches&nbsp;:<br>
                        <span class="fw-bold">• Son code Parrain personnel et unique</span><br>
                        Le <a class="clickable text-purple" target="_blank">code Parrain
                            <img alt="link icon" class="mb-1" height="" src="assets/icons/link.svg" width="19px">
                        </a>
                        est le véritable sésame du parrainage.<br>
                        Il circule avec fluidité de vous à votre client, puis de votre client à ses proches, puis de
                        certains* de ses proches à vous** à nouveau.<br>
                        <span class="fw-bold">• Ses canaux de transmission</span><br>
                        Votre client dispose de tous les canaux pour envoyer des messages à ses proches
                        <span class="text-purple clickable">(<a (click)="openPreview('email')">email</a>,
                            <a (click)="openPreview('sms')">SMS</a>,
                            <a (click)="openPreview('social')">réseaux sociaux</a>)
                        </span>.
                        Il dispose aussi d’un QR code qu’il lui suffit de faire flasher à un proche pour qu’il prenne RDV
                        avec vous.<br>
                        <span class="fw-bold">• Divers</span><br>
                        Votre client dispose enfin de diverses aides pour suivre ses parrainages, relancer ses filleuls, etc
                        <br><br>
                        <span class="small fst-italic">*ceux qui viennent ouvrir un compte<br>
                            **ou à une autre agence mais c’est rare
                        </span>
                    </p>
                </div>
            }
            <!--   Step 1   -->
            @if (step === 1) {
                <div class="pb-5">
                    @if (!showSecondSlideStep2) {
                        <div class="carousel carousel-fade slide" data-bs-ride="true" id="carouselIndicators">
                            <div class="carousel-indicators">
                                <button aria-current="true" aria-label="Slide 1" class="active"
                                        data-bs-slide-to="0" data-bs-target="#carouselIndicators" type="button"></button>
                                <button aria-label="Slide 2" data-bs-slide-to="1" data-bs-target="#carouselIndicators"
                                        type="button"></button>
                                <button aria-label="Slide 3" data-bs-slide-to="2" data-bs-target="#carouselIndicators"
                                        type="button"></button>
                            </div>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img [src]="env.cdnUrl + '/gift/img/sponsorship/smartphone-slide1.jpg'"
                                         alt="slide 1" class="d-block m-auto" height="" width="">
                                </div>
                                <div class="carousel-item">
                                    <img [src]="env.cdnUrl + '/gift/img/sponsorship/smartphone-slide2.jpg'"
                                         alt="slide 2" class="d-block m-auto" height="" width="">
                                </div>
                                <div class="carousel-item">
                                    <img [src]="env.cdnUrl + '/gift/img/sponsorship/smartphone-slide3.jpg'"
                                         alt="slide 3" class="d-block m-auto" height="" width="">
                                </div>
                            </div>
                        </div>
                        <div class="carousel-indicators-space"></div>
                    } @else {
                        <div class="carousel carousel-fade slide" data-bs-ride="true" id="carouselIndicators2">
                            <div class="carousel-indicators">
                                <button aria-current="true" aria-label="Slide 1" class="active"
                                        data-bs-slide-to="0" data-bs-target="#carouselIndicators" type="button"></button>
                                <button aria-label="Slide 2" data-bs-slide-to="1" data-bs-target="#carouselIndicators"
                                        type="button"></button>
                                <button aria-label="Slide 3" data-bs-slide-to="2" data-bs-target="#carouselIndicators"
                                        type="button"></button>
                            </div>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img [src]="env.cdnUrl + '/gift/img/sponsorship/smartphone-slide3.jpg'"
                                         alt="slide marketplace 1" class="d-block m-auto" height="" width="">
                                </div>
                                <div class="carousel-item">
                                    <img [src]="env.cdnUrl + '/gift/img/sponsorship/smartphone-slide2.jpg'"
                                         alt="slide marketplace 2" class="d-block m-auto" height="" width="">
                                </div>
                                <div class="carousel-item">
                                    <img [src]="env.cdnUrl + '/gift/img/sponsorship/smartphone-slide1.jpg'"
                                         alt="slide marketplace 3" class="d-block m-auto" height="" width="">
                                </div>
                            </div>
                        </div>
                        <div class="carousel-indicators-space"></div>
                    }
                </div>
            }
            <!--   Step 2   -->
            @if (step === 2) {
                <div>
                    @if (!showSms && !showSocialNetwork) {
                        <div [innerHTML]="emailPreviewSponsored"></div>
                    }
                    @if (showSms)  {
                        <div [innerHTML]="smsPreview" class="mt-5 p-3 border"></div>
                    }
                    @if (showSocialNetwork) {
                        <div [innerHTML]="sharePreview" class="mt-5 p-3 border"></div>
                    }
                </div>
            }
        </div>
    </div>
</div>

