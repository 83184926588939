import {Component, EventEmitter, Output} from '@angular/core';
import {ConsumerSmall} from '../../../consumers/models/consumerSmall.model';
import {catchError, map, Observable, of, switchMap, tap} from 'rxjs';
import {ConfigurationService, MsConsumersService, Offer} from '@isifid/core';
import {SponsorshipService} from '../../../../shared/services/sponsorship.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-validate',
    templateUrl: './validate.component.html'
})
export class ValidateComponent {
    @Output() validateEvent = new EventEmitter<{ action: string, sponsor: ConsumerSmall, offersSponsor: void | Offer[] }>();
    private consumerSponsor: ConsumerSmall;
    private offersSponsor: any;

    constructor(
        private configurationService: ConfigurationService,
        private msConsumersService: MsConsumersService,
        private sponsorshipService: SponsorshipService,
        private readonly _snackBar: MatSnackBar
    ) {
    }

    consumerFromSearchBar(event: ConsumerSmall): void {
        this.consumerSponsor = event;
        if (this.consumerSponsor) {
            this.getOfferSponsor()
                .subscribe({
                    next: () => this.validateEvent.emit({action: 'register', sponsor: this.consumerSponsor, offersSponsor: this.offersSponsor}),
                    error: () => console.error('Error while getting sponsor offer')
                });
        }
    }

    getOfferSponsor(): Observable<void | Offer[]> {
        const isProSponsor = false;
        const observer = this.sponsorshipService.settings.professionalEnabled ?
            this.isSponsorPro().pipe(switchMap(s => this.sponsorshipService.getSponsorOffers(s))) :
            this.sponsorshipService.getSponsorOffers(isProSponsor);

        return observer.pipe(
            tap(offerSponsors => {
                this.offersSponsor = offerSponsors;
                if (!this.offersSponsor?.[0]) {
                    console.error('Missing sponsor offers for sponsorship operation');
                    this._snackBar.open('Une erreur est survenue. Merci de contacter le support', 'X');
                }
            })
        );
    }

    private isSponsorPro(): Observable<boolean> {
        return this.msConsumersService.getConsumer(this.consumerSponsor.id)
            .pipe(
                map(sponsorConsumer => {
                    return sponsorConsumer?.configuration && this.configurationService.getValueByKey(sponsorConsumer.configuration, 'typology') === 'pro';
                }),
                catchError(() => of(false))
            );
    }
}
