import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GiftService} from '../../../shared/services/gift.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StatsService} from '../../../shared/services/stats.service';
import {DateService, GiftUser, UserService} from '@isifid/core';
import {Router} from '@angular/router';
import {SponsorshipService} from '../../../shared/services/sponsorship.service';
import {ExcelService} from '@isifid/reward';
import {MatTableDataSource} from '@angular/material/table';
import {GiftUserService} from '../../../shared/services/gift-user.service';
import {filter} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {TitleCasePipe} from '@angular/common';

@Component({
    selector: 'app-tracking',
    templateUrl: './tracking.component.html'
})
export class TrackingComponent implements OnInit {
    colNames: Array<Array<string>>;
    displayedColumns: Array<Array<string>>;

    dataSource = new MatTableDataSource<any>();
    dataSource2 = new MatTableDataSource<any>();
    loading = true;

    giftUser: GiftUser;
    branchList = [];

    private sponsors = [];
    private sponsored = [];

    trackingForm: FormGroup;

    private inputBranchCode: string;
    private sponsorshipOperation: any;
    private professionalEnabled = false;

    @ViewChild('sponsorSort') sponsorSort: MatSort;
    @ViewChild('aggregatedGiftUserStatsTableSort') aggregatedGiftUserStatsTableSort: MatSort;
    @ViewChild('sponsorPaginator') sponsorPaginator: MatPaginator;
    @ViewChild('aggregatedGiftUserStatsTablePaginator') aggregatedGiftUserStatsTablePaginator: MatPaginator;

    private giftUsers: {
        id: any;
        firstName: any;
        lastName: any;
        email: any;
        branchCode: any;
        sponsorCount: any,
        sponsoredCount: any
    }[] = [];

    constructor(
        private cdRef: ChangeDetectorRef,
        readonly giftService: GiftService,
        private readonly excelService: ExcelService,
        private readonly formBuilder: FormBuilder,
        private readonly dateService: DateService,
        private readonly giftUserService: GiftUserService,
        private readonly router: Router,
        private readonly sponsorshipService: SponsorshipService,
        private readonly statsService: StatsService,
        private readonly userService: UserService,
        private readonly _snackBar: MatSnackBar,
        private titleCase: TitleCasePipe
    ) {
    }

    ngOnInit(): void {
        this.giftUserService.getGiftUser().pipe(filter(s => !!s)).subscribe(s => {
            this.giftUser = s;
            if(!this.giftUser.branchList) this.giftUser.branchList = [];
            if (this.giftUser.branchList.length > 1) this.initGiftUserBranchList();
        });
        this.initColumns();
        this.initOperation();
        this.resetForm();
        this.loading = false;
    }

    initColumns() {
        const customId = this.giftService.giftNetworkVariables.idName || 'ID';

        this.displayedColumns = [
            ['email', 'externalId', 'firstName', 'lastName', 'mobile', 'sponsoredCount', 'sponsorCodeSentAt', 'giftUserEmail', 'branchCode', 'link'],
            this.statsService.getStatsSponsorshipDisplayedColumns()[5]
        ];
        this.colNames = [
            ['Adresse email client', this.titleCase.transform(customId), 'Nom', 'Prénom', 'Mobile',
                'Nombre de filleuls', 'Date envoi code parrain', 'Email du conseiller', 'Agence (code)', ''],
            this.statsService.getStatsSponsorshipColNames(null)[5]
        ];
        if (this.sponsorshipService.settings?.professionalEnabled) {
            this.professionalEnabled = true;
            this.displayedColumns[0].splice(6, 0, 'isPro');
            this.colNames[0].splice(6, 0, 'Professionnel');
        }
    }

    initOperation() {
        this.sponsorshipOperation = this.sponsorshipService.operation;
        if (!this.sponsorshipOperation) this._snackBar.open('Aucune opération de parrainage', 'X');
    }

    resetForm(): void {
        this.dataSource = undefined;
        if (this.sponsorshipOperation) {
            this.trackingForm = this.formBuilder.group({
                start: [this.dateService.computeDate(this.sponsorshipOperation.startDate, 'yyyy-MM-dd'), [Validators.required, Validators.minLength(10),
                    Validators.maxLength(10)]],
                end: [this.dateService.computeDate(new Date(), 'yyyy-MM-dd'), Validators.required],
                inputBranchCode: this.giftUser.branchList.length == 1 ? this.giftUser.branchList[0] : '',
                sponsorsOnly: this.hasRole('GIFT_ADVISOR'),
                preDefinedRange: 'all'
            });
            this.trackingForm.controls.preDefinedRange.valueChanges.subscribe(res => this.updateRange(res));
        }
    }

    hasRole(role: string): boolean {
        return this.userService.hasRole(role);
    }

    getStats(): void {
        if (this.trackingForm.invalid) return;

        if (!this.sponsorshipOperation) {
            this._snackBar.open('Aucune offre parrainage', 'X');
            return;
        }
        this.loading = true;
        this.dataSource = undefined;
        this.sponsors = [];
        const filters = {
            start: this.dateService.computeDate(this.trackingForm.get('start').value, 'yyyy-MM-dd'),
            end: this.dateService.computeDate(this.trackingForm.get('end').value, 'yyyy-MM-dd', 1)
        };

        // Filter by gift user id
        if (this.trackingForm.get('sponsorsOnly').value) filters['giftUserId'] = this.giftUser.id;

        // Filter by branch
        if (this.trackingForm.get('inputBranchCode').value) filters['branchList'] = this.trackingForm.get('inputBranchCode').value;
        // By default, we filter by branches
        else if (this.giftUser.branchList.length > 1) filters['branchList'] = this.giftUser.branchList;
        else filters['branchList'] = this.giftUser.branchList[0];

        this.statsService.getSponsorshipStat(this.sponsorshipOperation.id, [], filters).subscribe({
            next: (stats) => {
                this.sponsors = this.statsService.formatSponsorStats(stats.sponsorStats, this.sponsors, false,
                    this.professionalEnabled);
                this.sponsored = this.statsService.formatSponsoredStats(stats.sponsoredStats, this.sponsored, false,
                    this.professionalEnabled);

                // Get all giftUsers entity
                this.sponsors
                    .filter(s => s.giftUserEmail)
                    .forEach(row => {
                        // Don't add duplicates
                        // A gift user is considered as duplicate if it has the same email and branchCode
                        if (this.giftUsers.some(giftUser => giftUser.email === row.giftUserEmail && giftUser.branchCode === row.branchCode)) return;

                        const sponsorCount = this.sponsors
                            .filter(t => t.giftUserEmail === row.giftUserEmail && t.branchCode === row.branchCode).length;
                        const sponsoredCount = this.sponsored
                            .filter(t => t.giftUserEmail === row.giftUserEmail && t.branchCode === row.branchCode).length;

                        const giftUser = {
                            id: row.giftUserId,
                            firstName: row.giftUserFirstName,
                            lastName: row.giftUserLastName,
                            email: row.giftUserEmail,
                            branchCode: row.branchCode,
                            sponsorCount: sponsorCount,
                            sponsoredCount: sponsoredCount
                        };
                        this.giftUsers.push(giftUser);
                    });

                this.dataSource = new MatTableDataSource<unknown>(this.sponsors);
                this.dataSource.sort = this.sponsorSort;
                this.dataSource.paginator = this.sponsorPaginator;
                this.dataSource2 = new MatTableDataSource<unknown>(this.giftUsers);
                this.dataSource2.sort = this.aggregatedGiftUserStatsTableSort;
                this.dataSource2.paginator = this.aggregatedGiftUserStatsTablePaginator;
                this.cdRef.detectChanges();
                this.loading = false;
            }, error: () => {
                this._snackBar.open('Une erreur est survenue', 'X');
                this.loading = false;
            }
        });
    }

    download(): void {
        if (!this.sponsors) return;
        const aoa: any[] = [[], []];

        // Generate first tab
        this.setFirstTab(aoa[0]);
        // Generate aggregated giftUser stats tab
        this.setAggregatedGiftUserStatsTab(aoa[1]);

        const fileName = this.sponsorshipOperation.name;
        this.excelService.exportAsExcelFileAoA(
            aoa,
            `${fileName}_${new Date().toISOString().substring(0, 10)}`,
            ['Liste des parrainages', 'Liste agrégée']
        );
    }

    private updateRange(value) {
        this.dataSource = undefined;
        const start = new Date();
        const end = new Date();
        if (value === '7') {
            start.setDate(start.getDate() - 7);
        } else if (value === '30') {
            start.setDate(end.getDate() - 30);
        } else if (value === 'year') {
            start.setDate(1);
            start.setMonth(0);
        }
        this.trackingForm.get('start').setValue(this.dateService.computeDate(start, 'yyyy-MM-dd'));
        this.trackingForm.get('end').setValue(this.dateService.computeDate(end, 'yyyy-MM-dd'));
    }

    private initGiftUserBranchList() {
        this.branchList = this.giftUser.branchList.filter(branchCode => branchCode);
        this.inputBranchCode = this.branchList.toString();
    }

    private setFirstTab(aoa): void {
        aoa.push(this.colNames[0]);
        // If displayBranches add branchName
        this.sponsors.forEach(sponsorStats => {
            if (this.professionalEnabled) {
                aoa.push([sponsorStats.email, sponsorStats.externalId, sponsorStats.firstName, sponsorStats.lastName,
                    sponsorStats.mobile, sponsorStats.sponsoredCount, sponsorStats.isPro,
                    this.dateService.computeDate(new Date(sponsorStats.sponsorCodeSentAt), 'yyyy-MM-dd'),
                    sponsorStats.giftUserEmail, sponsorStats.branchCode]);
            } else {
                aoa.push([sponsorStats.email, sponsorStats.externalId, sponsorStats.firstName, sponsorStats.lastName,
                    sponsorStats.mobile, sponsorStats.sponsoredCount,
                    this.dateService.computeDate(new Date(sponsorStats.sponsorCodeSentAt), 'yyyy-MM-dd'),
                    sponsorStats.giftUserEmail, sponsorStats.branchCode]);
            }
        });
        aoa.push(['']);
        aoa.push(['']);
        if (this.trackingForm?.get('sponsorsOnly').value) {
            aoa.push(['Suivi Parrainage Conseiller', this.userService.getUser().lastName, this.userService.getUser().firstName,
                'Email', this.userService.getUser().email]);
        } else {
            aoa.push(['Suivi Parrainage Point de vente : ', this.giftUser.branchList[0]]);
        }
        const fileName = this.sponsorshipOperation.name;
        aoa.push(['Opération : ', fileName]);
    }

    private setAggregatedGiftUserStatsTab(aoa): void {
        aoa.push(this.colNames[1]);

        if (this.giftUsers.length === 0) {
            aoa.push(['Liste agrégée']);
        } else {
            for (const giftUser of this.giftUsers) {
                aoa.push([giftUser.firstName, giftUser.lastName, giftUser.email, giftUser.branchCode, giftUser.sponsorCount, giftUser.sponsoredCount]);
            }
        }
    }
}
