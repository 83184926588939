@if (!loading) {
    <div class="py-5">
        @if (!rewardSponsoredSuccessful && !rewardSponsorSuccessful) {
            <div class="col-10 col-lg-7 mx-auto">
                @if (consumerSponsor && !consumerSponsored) {
                    <app-form [sponsor]="consumerSponsor"
                              (rewardSponsorshipEvent)="checkEligibilityBeforeRewarding($event)" />
                }

            </div>
        }

        @if (rewardSponsoredSuccessful && rewardSponsorSuccessful && !uiService.rewardWithCash) {
            <h3 class="mt-5 pt-4"> Merci !<br>
                {{
                    sponsorshipService.settings.sponsoredAmount > 0 ?
                    'Le Parrain et le filleul ont bien été récompensés.' :
                    'Le parrainage est enregistré.'
                }}
            </h3>
        }
        @if ((rewardSponsoredSuccessful || rewardSponsorSuccessful) && uiService.rewardWithCash) {
            <h3 class="mt-5 pt-4"> Merci !<br>Vos clients recevront prochainement leur récompense</h3>
        }
        @if (rewardSponsoredSuccessful && !rewardSponsorSuccessful && !uiService.rewardWithCash) {
            <h3 class="mt-5 pt-4"> Merci !<br>Le filleul a bien été récompensé.</h3>
        }
        @if (!rewardSponsoredSuccessful && rewardSponsorSuccessful && !uiService.rewardWithCash) {
            <h3 class="mt-5 pt-4"> Merci !<br>Le Parrain a bien été récompensé.</h3>
        }
        @if (!rewardSponsoredSuccessful && sponsoredAlreadyRewarded && rewarding) {
            <h3>
                Le filleul n'a pas été récompensé, car il est déjà filleul d'un parrain.
            </h3>
        }
        @if (!rewardSponsoredSuccessful && !sponsoredAlreadyRewarded && rewarding) {
            <h3>
                Une erreur est survenue lors de la récompense.
            </h3>
        }
        @if (rewardSponsoredSuccessful || rewardSponsorSuccessful) {
            @if (sponsorCreated) {
                <div class="my-5">
                    <h3>Votre filleul a bien été inscrit comme parrain.</h3>
                    @if (consumerSponsored.mobile) {
                        <span>Un sms a lui été envoyé.</span>
                    }
                </div>
            }
            <div class="my-5">
                @if (sponsoredNeedToWaitBeforeBecomingSponsor()) {
                    <button (click)="generateNewSponsorCode()" [disabled]="sponsorCreated" class="btn btn-validate">
                         Inscrire comme Parrain
                    </button>
                }
            </div>
            <div  class="mt-2">
                <button [routerLink]="'/home'" class="btn btn-validate"> Retour </button>
            </div>
        }
    </div>
}
